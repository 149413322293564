import React from 'react'
import Layout from '../components/layout'
import { VideoClip } from '../components/backgroundVideo'
import TemplateDevlog from '../templates/templateDevlog'
import SEO from '../components/seo'

export default () => {
    return (
        <Layout>
            <SEO 
                title='devlogs' 
                description='📜 My Game-dev journey.'
                pathname='/devlogs/'
            />
            <VideoClip clip='CopCar'/>
            <TemplateDevlog/>
        </Layout>
    )
}