import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './templateDevlog.module.css'
import Img from 'gatsby-image'

const TemplateDevlog = ({width = `100%`}) => {

    const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        title
                        devlog_summary
                        devlog_url
                        game_icon {
                            childImageSharp {
                                fluid (maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `)

    return (
        <section className={styles.section} id='devlogs'>
            <ul className={styles.devlogItemList} style={{width: `${width}`}}>
                {
                    data.allMarkdownRemark.edges.map(({node}) => (
                        <li 
                            key={node.frontmatter.title}
                            className={styles.devlogItem}
                        >
                            <a 
                                href={node.frontmatter.devlog_url} 
                                className={styles.devlogItemLink}
                            >
                                <div className={styles.devlogIconHolder}>
                                    <Img 
                                        fluid={node.frontmatter.game_icon.childImageSharp.fluid} 
                                        alt={`${node.frontmatter.title}`}
                                        className={styles.devlogIcon}
                                    />
                                </div>
                                <h1>{node.frontmatter.title}</h1>
                                <p>{node.frontmatter.devlog_summary}</p>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </section>
    )
}

export default TemplateDevlog